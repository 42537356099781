<template>
  <!-- sample template -->
  <div id="cricket-page-wrapper" class="sport-page-wrapper">
    <div id="loading">
      <img src="https://sprodm.uni247.xyz/images-v3/favicon/android-chrome-192x192.png" alt="logo" />
      Loading...
    </div>
    <div class="sport-widgets">
      <div id="sport-root"
        data-ck-props='{"theme":{"pbgc":"000","sbgc":"1a1a1a","pc":"D2B79E","sc":"333","tc":"333","tpc":"fff","tsc":"999","qlbc":"333","primaryColorLinearGradientParams":{"deg":"","color1":"F4E7D6","opacity1":"","color2":"D2B79E","opacity2":"","color3":"","opacity3":""},"quickLink":{"sponsor":true},"burger":false,"eventList":{"statementLink":true}},"displayHeader":true,"autoBetSuffix":"<https://sprodm.uni247.xyz/>"}'>
      </div>
    </div>

    <!-- 站内logo -->
    <div class="back" @click="$router.back()">
      <img src="@/assets/images/luckysprot-logo.png" class="back-logo">
      <img src="@/assets/images/luckysprot-back.png" class="back-icon">
    </div>

  </div>
</template>

<script>
import { login_lucky_sport_game_api } from "@/api/cybSports.js"
export default {
  data() {
    return {
      isOpen: false,
      isFisrtOpen: true
    }
  },
  methods: {
    init(token) {
      if (window.location.hash === "") window.location.hash = "#/";
      // widget loader
      (function (a, b, c, d, e, f, g, h, i) {
        setTimeout(() => {
          console.log(document.querySelector(".sport-logo-container"))
        }, 500)
        return (
          a[e] ||
          ((i = a[e] =
            function () {
              (a[e].q = a[e].q || []).push(arguments);
            }),
            (g = b.createElement(c)),
            (h = b.getElementsByTagName(c)[0]),
            (g.async = 1),
            (g.src = d),
            h.parentNode.insertBefore(g, h))
        );
      })(
        window,
        document,
        "script",
        `https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,
        "CRIC"
      );
      // 此處帶入已取得遊客權杖(token)，後續更換一般權杖也使用此函數
      window.CRIC({
        token,
      });

    },
    async login() {
      const { data: res } = await login_lucky_sport_game_api()
      if (res.code === 1) {
        this.init(res.data.token);
      }
    },
    scroll() {
      window.removeEventListener("scroll", this.scroll)
      this.isFisrtOpen = false
    }
  },
  mounted() {
    this.login()
    window.addEventListener("scroll", this.scroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll)
  }
}
</script>

<style scoped lang="less">
.app .app-home,
body {
  background: #111;
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(17, 17, 17);
}

body,
html {
  text-size-adjust: none;
  margin: 0;
}

.sport-page-wrapper {
  background-color: rgb(0, 0, 0);
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.sport-page-wrapper .sport-widgets {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-family: Avenir Next;
  flex-direction: column;
}

.back {
  width: 81px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, #3F1B00, #B45501);
  border-radius: 0px 19px 19px 1px;
  position: fixed;
  left: 0;
  bottom: 150px;
  padding: 0 16px 0 4px;
  cursor: pointer;

  &-logo {
    width: 35px;
    height: 25px;
  }

  &-icon {
    width: 18px;
    height: 16px;
  }
}
</style>